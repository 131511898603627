#panell {
  margin-top: 100px;
  padding: 0px;
}

#loginbtn {
  float: right;
  margin-bottom: 40px;
  margin-right: 20px;
}

#errormessage {
  text-emphasis-color: red; 
  color: red;
}

#title {
  color: white;
  padding: 8px;
  margin-top: 0px;
  text-align: center;
}

form {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

#email, #password {
  margin-left: 20px;
  margin-right: 20px;
}

.modal{
  max-height:100%;
  overflow:auto;
  transform: translateY(5%) !important;
 }

input[type="submit"]{
  width: 100%;
  margin-top: 50px;
}

.modal-close{
  position: absolute;
  top:5px;
  right:5px;
  padding:0;
  margin:0;
}