.container {
    margin: 0 auto;
    max-width: 1980px;
    width: 100%;
}

@media only screen {
  .container {
    width: 95%;
  }
}